@import "@/styles";

.footer {
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15.3rem;
  margin: 0 6.7rem 0 3rem;

  @include mobile {
    height: 15rem;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 2rem;
  }
}

.logo {
  font-size: 1.6rem;
  text-align: end;

  @include mobile {
    @include font-size(1.1rem);
    text-align: center;
  }
}

@mixin align-center($direction) {
  display: flex;
  align-items: center;

  @if $direction == "column" {
    flex-direction: column;
  }
}

@mixin flex-center($direction: "") {
  display: flex;
  align-items: center;
  justify-content: center;

  @if $direction == "column" {
    flex-direction: column;
  }
}

@mixin flex($direction, $align) {
  display: flex;
  align-items: center;

  @if $direction == "column" {
    flex-direction: column;
  }
  @if $align == "center" {
    justify-content: center;
  }
  @if $align == "space-between" {
    justify-content: space-between;
  }
  @if $align == "space-around" {
    justify-content: space-around;
  }
  @if $align == "space-evenly" {
    justify-content: space-evenly;
  }
}

@mixin fixed-center() {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-center-x() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-center-y() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin card-container($size) {
  @if $size == "s" {
    height: 22.9rem;
    width: 12.7rem;
  }
  @if $size == "m" {
    height: 24.8rem;
    width: 13.7rem;
  }
  @if $size == "l" {
    height: 49.1rem;
    width: 27.4rem;
  }
}

@mixin full-size() {
  height: 100%;
  width: 100%;
}

@mixin fixed-full-size() {
  height: 100vh;
  width: 100vw;
}

@mixin bar($height, $marginSide) {
  border-right: 1px solid $bluegray;
  height: $height;
  margin: 0 $marginSide;
}

@mixin hide-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: #{$breakpoint-mobile}) {
    @content;
  }
}

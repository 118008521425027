$white: #fff;
$gray: #797979;
$backgroundblue: #edf3f9;
$pastelblue1: #ebf2f8;
$pastelblue2: #d8e2ed;
$pastelblue3: #c1cee3;
$normalblue1: #e9ebfc;
$normalblue2: #ccd0f6;
$normalblue3: #9a9ec9;
$darkblue: #272a49;
$red: #ea3f27;
$black: #000;

@mixin font-size($size) {
  font-size: $size;
  line-height: calc($size * 1.5);
}

@mixin font-size-10() {
  font-size: 1rem;
  line-height: 1.5rem;
}
@mixin font-size-12() {
  font-size: 1.2rem;
  line-height: 1.7rem;
}
@mixin font-size-13() {
  font-size: 1.3rem;
  line-height: 1.9rem;
}
@mixin font-size-15() {
  font-size: 1.5rem;
  line-height: 2.2rem;
}
@mixin font-size-20() {
  font-size: 2rem;
  line-height: 2.9rem;
}

@mixin bold() {
  font-weight: 700;
}

@mixin background-clip-text($background) {
  background: $background;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

@import "@/styles";

html {
  cursor: default;
  font-size: min(0.6944vw, 1.143vh); /* trick: 10px in 1440x875 resolution */

  @include mobile {
    font-size: min(2.6666vw, 1.231vh); /* trick: 10px in 375x812 resolution */
  }
}

body {
  margin: 0;
  font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  padding-top: 16rem;

  @include mobile {
    padding-top: 7rem;
  }
}

#__next {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: black;
}

.hidden {
  visibility: hidden;
}

.none {
  display: none;
}

@import "@/styles";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5.5rem 6.7rem;
  position: fixed;
  z-index: 2;
  background: white;
  top: 0;
  /* width: 100% */
  left: 0;
  right: 0;
  @include font-size(1.2rem);

  &.home {
    background: #edf3f9;
  }
}

.syl-home {
  display: inline-flex;
  align-items: center;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  display: inline-flex;
  gap: 0.6rem;
  color: $gray;
  margin-left: 4rem;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
}

.icon_wrapper {
  width: 10.4rem;
  height: 22rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1rem;
  flex-direction: column;
  display: flex;
  padding: 1rem 0;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 5rem;
  }
}

.link_wrapper {
  width: 11rem;
  height: 16rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1rem;
  flex-direction: column;
  display: flex;
  padding: 1rem 0;

  .text {
    color: $gray;
    display: flex;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 5rem;

    &.selected {
      color: #ea3f27;
    }
  }
}

.global {
  margin-right: 0.6rem;
  top: 2px;
  position: relative;
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-left: 2rem;
}

.button {
  @include font-size(1.3rem);
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3.3rem;
  padding: 0 2rem;
  box-shadow: 3px 3px 25px rgba(193, 206, 227, 0.5);
  border-radius: 1rem;

  .svg {
    margin-right: 0.5rem;

    &.syl {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  @include mobile {
    height: 3rem;
    @include flex-center("");
    margin-left: 1rem;
    @include font-size(1rem);
    padding: 0 1.5rem;
  }

  &.filled {
    color: white;
    background: #ea3f27;

    &.soon {
      background: rgba(154, 158, 201, 0.5);
    }

    @include mobile {
      padding: 0 2rem;
    }
  }

  &.outlined {
    color: #ea3f27;
    background: #ffffff;
    border: 0.1rem solid #ea3f27;

    .svg {
      fill: $red;
    }
    @include mobile {
      padding: 0 1rem;
    }
  }
}

@include mobile {
  .header {
    padding: 1rem 2rem;
  }
}

.soon {
  &::after {
    content: "SOON";
    position: absolute;
    @include font-size(1rem);
    font-weight: 700;
    color: rgba(234, 63, 39, 0.5);
    margin-top: -2.5em;
    margin-left: -1rem;
    @include mobile {
      margin-top: -2.5em;
      margin-left: 0;
    }
  }
}

@import "@/styles";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu {
  /*nav menu hidden for mobile*/
  display: flex;
  flex-direction: column;
  position: absolute;
  list-style: none;
  top: -2000%;
  left: 0;
  align-items: center;
  margin: auto;
  padding: 2rem 0;
  width: 100%;
  background: white;
  z-index: 0;
  transition: all 0.55s ease-in-out;

  &.home {
    background: #edf3f9;
  }

  a {
    color: black;
    font-weight: 550;
    font-size: 1.5rem;

    &.current {
      color: red;
    }

    &:hover {
      color: #e63946;
    }
  }
}
.item {
  margin: 2rem 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;

  &.icon_wrapper {
    width: auto;
  }

  .soon {
    &::after {
      content: "SOON";
      position: absolute;
      @include font-size(1rem);
      font-weight: 700;
      color: rgba(234, 63, 39, 0.5);
      margin-top: -1.5em;
      margin-left: -3rem;
    }
  }
}

.opensea_wrapper {
  margin-top: auto;
}

.icon_wrapper {
  display: flex;
  margin: 0;

  .icon {
    margin: 0 0.2rem;
    padding: 0.4rem 0.5rem;
    cursor: pointer;
  }
}

.lang_wrap {
  padding: 0.5rem 0 5rem;
  font-weight: 700;
  margin-top: 1rem;

  .lang {
    padding: 0 1rem;
    margin: 0.1rem 0;
    cursor: pointer;

    font-size: 1.8rem;

    &:first-child,
    &:nth-child(2) {
      border-right: 1px solid black;
    }

    &.red {
      color: #ea3f27;
    }
  }
}

@import "@/styles";

.link {
  color: $gray;
  margin-left: 4rem;
  font-weight: 700;

  &.current {
    color: red;
  }
}

.soon {
  &::after {
    content: "SOON";
    position: absolute;
    @include font-size(1rem);
    font-weight: 700;
    color: rgba(234, 63, 39, 0.5);
    margin-top: -1em;
    margin-left: -3rem;
  }
}
